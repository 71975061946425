<script setup lang="ts">
import { FieldNodePageFieldSidebarWidgets } from '~/api/graphql/generated'

const props = defineProps<{
  sidebar?: FieldNodePageFieldSidebarWidgets[]
}>()

const slots = useSlots()
const sections = useSidebarSections(props.sidebar)
const shouldDisplay = computed(() => {
  return sections.length > 0 || slots.default?.()
})
</script>

<template>
  <Section v-if="shouldDisplay" v-animate="{ cluster: true }">
    <div class="sidebar t-10 t-o-1 tl-4">
      <div class="sidebar-content">
        <component
          :is="section?.name"
          v-for="(section, index) in sections"
          :key="`${section?.name}-${index}`"
          :entity="section.entity"
        />
        <slot />
      </div>
    </div>
  </Section>
</template>

<style scoped lang="scss">
.section-sidebar {
  @include breakpoint(tl) {
    @include fluid(--margin, 96px, 128px);

    margin-bottom: 0;
  }
  + :deep(.section) {
    margin-top: 0;
    // @include fluid(--margin, 96px, 128px);
  }
}
.sidebar {
  position: relative;
  @include breakpoint(tl) {
    --offset: 8;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 35px;

    @include breakpoint(tl) {
      position: absolute;
      gap: 50px;
    }
  }
}

.sidebar-content {
  width: 100%;
  @include breakpoint(tl) {
    padding-left: 48px;
  }
}
</style>
